<template>
    <AlertManagment/>
</template>
<script>

import AlertManagment from './AlertManagment.vue';
export default {
    name: 'EventManagment',
    components: { AlertManagment }
};
</script>